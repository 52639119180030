// auth 2.0

// ActionTypes for Global
export const SET_LANG = "SET_LANG";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const FETCH_USER = "FETCH_USER";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_APPLICATION_TYPE = "SET_APPLICATION_TYPE";
export const SET_EXTENSION_VERSION = "SET_EXTENSION_VERSION";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SCREEN_ORIENTATION = "SET_SCREEN_ORIENTATION";
export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const SET_IS_EXTENSION_INSTALLED = " SET_IS_EXTENSION_INSTALLED";
export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";
export const SET_EXTENSION_ID = "SET_EXTENSION_ID";
export const SET_IS_NEW_USER = "SET_IS_NEW_USER";
export const SET_IS_CREATING_NEW_USER = "SET_IS_CREATING_NEW_USER";
export const SET_IS_ONBOARDING_NEEDED_USER = "SET_IS_ONBOARDING_NEEDED_USER";
export const SET_USER_TRIAL_DATA = "SET_USER_TRIAL_DATA";
export const SET_HACKLE_EXPERIMENT_KEYS = "SET_HACKLE_EXPERIMENT_KEYS";
export const SET_PENDING_TRACK_EVENT = "SET_PENDING_TRACK_EVENT";
export const CLEAR_PENDING_TRACK_EVENTS = "CLEAR_PENDING_TRACK_EVENTS";
export const SET_USER_STUDY_CHALLENGE_DATA = "SET_USER_STUDY_CHALLENGE_DATA";

// ActionTypes for vdocs
export const SET_IS_WEB_CAPTURE_AVAILABLE = "SET_IS_WEB_CAPTURE_AVAILABLE";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const FETCH_DOCUMENT = "FETCH_DOCUMENT";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const MOVE_DOCUMENT = "MOVE_DOCUMENT";
export const CREATE_FOLDER = "CREATE_FOLDER";
export const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER";
export const SET_NOTE_BLOCKS = "SET_NOTE_BLOCKS";
export const SET_IS_SET_CAPTURE_AREA_BUTTON_CLICKED = "SET_IS_SET_CAPTURE_AREA_BUTTON_CLICKED";
// currentVideo should only be updated if we are 100% sure the same changes
// have been saved in the database to avoid inconsistency
export const SET_CURRENT_VIDEO = "SET_CURRENT_VIDEO";
export const UPDATE_CURRENT_VIDEO = "UPDATE_CURRENT_VIDEO";

export const FETCH_VIDEOS = "FETCH_VIDEOS";

export const REGISTER_VIDEO = "REGISTER_VIDEO";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_DELETED_DOCUMENTS = "FETCH_DELETED_DOCUMENTS";
export const SET_VIDEO_INDEX = "SET_VIDEO_INDEX";
export const SET_CAPTURE_INSERT_POSITION = "SET_CAPTURE_INSERT_POSITION";
export const SET_IS_EDITOR_SAVED = "SET_IS_EDITOR_SAVED";
export const SET_EDITOR_MODE = "SET_EDITOR_MODE";
export const TOGGLE_EDITOR = "TOGGLE_EDITOR";
export const SET_VIDEO_PLACEHOLDER_RECT = "SET_VIDEO_PLACEHOLDER_RECT";
export const SET_CAPTURE_AREA_RECT = "SET_CAPTURE_AREA_RECT";
export const SET_IS_CAPTURE_AREA_ACTIVE = "SET_IS_CAPTURE_AREA_ACTIVE";
export const SET_IS_HELP_MENU_OPEN = "SET_IS_HELP_MENU_OPEN";
export const SET_IS_SAVE_DOCUMENT_CALLED = "SET_IS_SAVE_DOCUMENT_CALLED";

export const REGISTER_CLIP_OCR = "REGISTER_CLIP_OCR";

export const SET_SHOW_SEARCH_POPUP = "SET_SHOW_SEARCH_POPUP"; // old name: shouldShowSearchPopup
export const SET_SHOW_SHARE_POPUP = "SET_SHOW_SHARE_POPUP";
export const SET_SHOW_IMAGE_MARKUP_POPUP = "SET_SHOW_IMAGE_MARKUP_POPUP";
export const SET_SHOW_IMAGE_FULL_SCREEN = "SET_SHOW_IMAGE_FULL_SCREEN";
export const SET_NEED_TO_SIGN_UP = "SET_NEED_TO_SIGN_UP";
export const SET_SHOW_DELETED_DOCUMENTS_POPUP = "SET_SHOW_DELETED_DOCUMENTS_POPUP";
export const SET_IS_LIST_OPEN = "SET_IS_LIST_OPEN";
export const SET_IS_EDITOR_OPTION_OPEN = "SET_IS_EDITOR_OPTION_OPEN";

export const SET_EDITOR_INSTANCE = "SET_EDITOR_INSTANCE";
export const SET_IS_READ_ONLY = "SET_IS_READ_ONLY";

export const SET_UNDO_INSTANCE = "SET_UNDO_INSTANCE";

export const SET_VIDEO_PLAYER_REF = "SET_VIDEO_PLAYER_REF";

export const SET_EDITOR_WRAPPER_CLASS_NAME = "SET_EDITOR_WRAPPER_CLASS_NAME";

export const SET_SHOW_PLACEHOLDER = "SET_SHOW_PLACEHOLDER"; // to show or hide the placeholder text in document editor

export const SET_EDITOR_LAST_ACTIVE_BLOCK_POSITION = "SET_EDITOR_LAST_ACTIVE_BLOCK_POSITION";

export const SET_IS_RECORD_ACTIVE = "SET_IS_RECORD_ACTIVE"; // to show recording state
export const SET_LOADER_BLOCK_KEYS = "SET_LOADER_BLOCK_KEYS";

export const SET_IS_GETTING_CLIP_RECORDING_MEDIA_PERMISSION = "SET_IS_GETTING_CLIP_RECORDING_MEDIA_PERMISSION";
export const SET_IS_PDF_UPLOADER_CLICKED = "SET_IS_PDF_UPLOADER_CLICKED";

// DESKTOP
export const SET_IFRAME_PORT = "SET_IFRAME_PORT";
export const SET_SELECTABLE_PROCESSES = "SET_SELECTABLE_PROCESSES";

// the version of slid desktop
export const SET_DESKTOP_VERSION = "SET_DESKTOP_VERSION";

// name and id of the selected app window
export const SET_SELECTED_SOURCE_DATA = "SET_SELECTED_SOURCE_DATA";

// the entire selected app rect
export const SET_DESKTOP_DEFAULT_CAPTURE_RECT = "SET_DESKTOP_DEFAULT_CAPTURE_RECT";

// the first and primary capture area
export const SET_DESKTOP_CAPTURE_RECT = "SET_DESKTOP_CAPTURE_RECT";

// the second capture area. (one that a user chooses as their custom default caoture area)
export const SET_DESKTOP_CAPTURE_RECT_2 = "SET_DESKTOP_CAPTURE_RECT_2";

// when the user is signed out, or auth token expires, we detect it in app.js but we wanna fire the alert in VideoDocumentEditor.js
export const SET_IS_GUEST_MODE_ALERT_SHOWN = "SET_IS_GUEST_MODE_ALERT_SHOWN";
export const SET_IS_DESKTOP_CAPTURE_AREA_SETTING = "SET_IS_DESKTOP_CAPTURE_AREA_SETTING";
export const SET_IS_DESKTOP_CAPTURE_AREA_SET = "SET_IS_DESKTOP_CAPTURE_AREA_SET";

//--------- DESKTOP END ------

// Get and set History Data
export const GET_DOCUMENT_HISTORY_LIST = "GET_DOCUMENT_HISTORY_LIST";
export const CREATE_DOCUMENT_HISTORY = "CREATE_DOCUMENT_HISTORY";

// pricing
export const SET_SHOW_REFERRAL_POPUP = "SET_SHOW_REFERRAL_POPUP";
export const SET_PROMOTION_ID = "SET_PROMOTION_ID";
export const SET_PAYMENT_LENGTH = "SET_PAYMENT_LENGTH";
export const SET_PAYMENTS_HISTORY_DATA = "SET_PAYMENTS_HISTORY_DATA";
export const SET_REFERRAL_CODE_DATA = "SET_REFERRAL_CODE_DATA";
export const SET_USER_COUNTRY = "SET_USER_COUNTRY";
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";

// pricing - pricing page
export const SET_PLAN_TYPE = "SET_PLAN_TYPE";
export const SET_PLAN_LENGTH = "SET_PLAN_LENGTH";
export const SET_MEMBERSHIP = "SET_MEMBERSHIP";
export const SET_IS_CASHBACK_USER = "SET_IS_CASHBACK_USER";
export const SET_IS_ELIGIBLE_FOR_CASHBACK = "SET_IS_ELIGIBLE_FOR_CASHBACK";
export const SET_IS_CASHBACK_OPTION_CHECKED = "SET_IS_CASHBACK_OPTION_CHECKED";
export const SET_IS_CHECKOUT_SECTION_VISIBLE_ON_MOBILE = "SET_IS_CHECKOUT_SECTION_VISIBLE_ON_MOBILE";

// For web snip capture
export const SET_IS_CAPTURE_AREA_SET_FOR_SNIP_CAPTURE = "SET_IS_CAPTURE_AREA_SET_FOR_SNIP_CAPTURE";
export const SET_CAPTURE_RECT = "SET_CAPTURE_RECT";
export const SET_IS_CAPTURE_AREA_SET_FOR_ONE_CLICK_CAPTURE = "SET_IS_CAPTURE_AREA_SET_FOR_ONE_CLICK_CAPTURE";
export const SET_IS_CAPTURE_AREA_RESET = "SET_IS_CAPTURE_AREA_RESET";

// For app loading state
export const SET_IS_EDITOR_LOADING = "SET_IS_EDITOR_LOADING";
export const SET_IS_EDITOR_NOTE_LOADING = "SET_IS_EDITOR_NOTE_LOADING";

// For Demo
export const SET_IS_WAITING_FOR_SIGN_IN = "SET_IS_WAITING_FOR_SIGN_IN";
export const SET_IS_ONBOARDING_VIDEO_PLAYING = "SET_IS_ONBOARDING_VIDEO_PLAYING";
export const SET_CURRENT_VIDEO_TIME = "SET_CURRENT_VIDEO_TIME";
export const SET_ACTIVE_BUTTON_TYPE = "SET_ACTIVE_BUTTON_TYPE";
export const SET_INSERT_SLT_BLOCKS_TO_DEMO_EDITOR = "SET_INSERT_SLT_BLOCKS_TO_DEMO_EDITOR";
export const SET_VISIBLE_SLT_BLOCKS_ON_SLT_VIEW = "SET_VISIBLE_SLT_BLOCKS_ON_SLT_VIEW";
export const SET_VISIBLE_SLT_BLOCKS_ON_NOTE = "SET_VISIBLE_SLT_BLOCKS_ON_NOTE";
export const SET_VISIBLE_IMAGE_BLOCKS = "SET_VISIBLE_IMAGE_BLOCKS";
export const SET_VISIBLE_GRAB_TEXT_BLOCKS = "SET_VISIBLE_GRAB_TEXT_BLOCKS";
export const SET_OVERLAY_MASK_MODE = "SET_OVERLAY_MASK_MODE";

export const SET_GRACE_PERIODS = "SET_GRACE_PERIODS";

//For tablet
export const SET_IS_IMAGE_CROPPING_MODAL_OPEN = "SET_IS_IMAGE_CROPPING_MODAL_OPEN";
export const SET_IMAGE_CROPPING_MODAL_IMAGE_SOURCE = "SET_IMAGE_CROPPING_MODAL_IMAGE_SOURCE";
export const SET_IMAGE_CROP_AREA = "SET_IMAGE_CROP_AREA";
export const SET_TABLET_DEFAULT_CROP_AREA = "SET_TABLET_DEFAULT_CROP_AREA";
export const SET_EDIT_IMAGE_CROP_AREA = "SET_EDIT_IMAGE_CROP_AREA";
export const SET_IS_TABLET_APP_OPENED_IN_SPLIT_VIEW = "SET_IS_TABLET_APP_OPENED_IN_SPLIT_VIEW";
export const SET_IS_USER_CAPTURED_BY_TABLET_BEFORE = "SET_IS_USER_CAPTURED_BY_TABLET_BEFORE";
export const SET_USER_TABLET_DEVICE_INFO = "SET_USER_TABLET_DEVICE_INFO";
export const SET_SHOULD_UPDATE_IMAGE_BY_CROP = "SET_SHOULD_UPDATE_IMAGE_BY_CROP";
export const SET_CROPPING_IMAGE_BLOCK_DATA = "SET_CROPPING_IMAGE_BLOCK_DATA";
export const SET_TABLET_ORIENTATION = "SET_TABLET_ORIENTATION";

// For transcription
export const SET_IS_STT_ACTIVE = "SET_IS_STT_ACTIVE";
export const ADD_STT_DATA = "ADD_STT_DATA";
export const SET_CURRENT_STT_DATA = "SET_CURRENT_STT_DATA";
export const SET_LAST_STT_DATA_AS_TRANSCRIBED = "SET_LAST_STT_DATA_AS_TRANSCRIBED";
export const SET_LEFT_STT_DATA_AS_TRANSCRIBED = "SET_LEFT_STT_DATA_AS_TRANSCRIBED";
export const SET_STT_ERROR = "SET_STT_ERROR";
export const SET_STT_ACTIVE_NOTE_BLOCK = "SET_STT_ACTIVE_NOTE_BLOCK";
export const SET_IS_STT_SESSION_STARTED = "SET_IS_STT_SESSION_STARTED";
export const SET_IS_VIDEO_PLAYING = "SET_IS_VIDEO_PLAYING";
export const SET_SHOW_SMART_LIVE_TEXT_VIEW = "SET_SHOW_SMART_LIVE_TEXT_VIEW";
export const SET_SMART_LIVE_TEXT_LANGUAGE = "SET_SMART_LIVE_TEXT_LANGUAGE";
export const SET_SHOW_EXTENSION_MEDIA_PERMISSION_MODAL = "SET_SHOW_EXTENSION_MEDIA_PERMISSION_MODAL";
export const SET_IS_STT_TOGGLED_ON = "SET_IS_STT_TOGGLED_ON";
export const SET_IS_EXTENSION_MEDIA_PERMITTED = "SET_IS_EXTENSION_MEDIA_PERMITTED";
export const SET_IS_STT_SOCKET_CONNECTED = "SET_IS_STT_SOCKET_CONNECTED";
export const SET_SHOW_DISABLE_MEDIA_RECORDING_MODAL = "SET_SHOW_DISABLE_MEDIA_RECORDING_MODAL";
export const SET_MEDIA_RECORDING_CHANGE_DIRECTION = "SET_MEDIA_RECORDING_CHANGE_DIRECTION";
export const SET_IS_STT_SUPPORTED = "SET_IS_STT_SUPPORTED";
export const SET_IS_STT_V2_SUPPORTED = "SET_IS_STT_V2_SUPPORTED";
export const SET_IS_GETTING_STT_MEDIA_PERMISSION = "SET_IS_GETTING_STT_MEDIA_PERMISSION";
export const SET_IS_GETTING_EXTENSION_MEDIA_PERMISSION = "SET_IS_GETTING_EXTENSION_MEDIA_PERMISSION";
export const UPDATE_PRIVILEGES = "UPDATE_PRIVILEGES";
export const SET_IS_MANUAL_ADDING_MODE = "SET_IS_MANUAL_ADDING_MODE";
export const SET_IS_STT_MORE_BUTTON_CLICKED = "SET_IS_STT_MORE_BUTTON_CLICKED";
export const RESET_STT_DATA = "RESET_STT_DATA";
export const SET_PROCESSED_STT_DATA = "SET_PROCESSED_STT_DATA";
export const SET_SHOW_PERMISSION_GUIDE_IN_TEXT_VIEW = "SET_SHOW_PERMISSION_GUIDE_IN_TEXT_VIEW";
export const SET_IS_LANGUAGE_SELECTED_FROM_HEADER = "SET_IS_LANGUAGE_SELECTED_FROM_HEADER";
export const SET_IS_SLT_STARTED_BY_USER = "SET_IS_SLT_STARTED_BY_USER";

// Day Pass
export const SET_DAYPASS_DATA = "SET_DAYPASS_DATA";

// For slid co pilot
export const SET_SHOW_SLID_GPT = "SET_SHOW_SLID_GPT";
export const SET_SLID_GPT_CHAT_ID = "SET_SLID_GPT_CHAT_ID";
export const SET_SLID_GPT_NOTE_EMBEDDINGS = "SET_SLID_GPT_NOTE_EMBEDDINGS";

// For searching
export const SET_SHOW_SEARCH_MODAL = "SET_SHOW_SEARCH_MODAL";
export const SET_SEARCH_KEYWORDS = "SET_SEARCH_KEYWORDS";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_SEARCH_CATEGORIES = "SET_SEARCH_CATEGORIES";
export const SET_SEARCH_SORTING_OPTION = "SET_SEARCH_SORTING_OPTION";

// For my note
export const SLID_EXPERT_TOGGLE_ACCOUNT = "SLID_EXPERT_TOGGLE_ACCOUNT";

// For editor & editor tools
export const SET_LEGACY_MARKUP_COLOR = "SET_LEGACY_MARKUP_COLOR";
export const SET_MARKUP_TOOL = "SET_MARKUP_TOOL";
export const SET_MARKUP_COLOR = "SET_MARKUP_COLOR";
export const SET_TOOL_SETTING_MENU_TYPE = "SET_TOOL_SETTING_MENU_TYPE";
export const SET_MARKUP_PEN_SIZE = "SET_MARKUP_PEN_SIZE";
export const SET_MARKUP_HIGHLIGHTER_SIZE = "SET_MARKUP_HIGHLIGHTER_SIZE";
export const SET_MARKUP_PEN_MODE = "SET_MARKUP_PEN_MODE";
export const SET_MARKUP_HIGHLIGHTER_MODE = "SET_MARKUP_HIGHLIGHTER_MODE";
export const SET_ACTIVE_MARKUP_OBJECT = "SET_ACTIVE_MARKUP_OBJECT";
export const SET_SHOW_HIGHLIGHTER_COLOR_GRID = "SET_SHOW_HIGHLIGHTER_COLOR_GRID";

// auth
export const SET_IS_SIGNING_UP = "SET_IS_SIGNING_UP";
export const SET_IS_SIGNING_IN = "SET_IS_SIGNING_IN";

//expert pricing
export const SET_EXPERT_CODE = "SET_EXPERT_CODE";
export const SET_SURVEY_INFO = "SET_SURVEY_INFO";

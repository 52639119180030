import {
  // for global
  SET_ERROR_MESSAGE,

  // for vdocs
  SET_DOCUMENT,
  SET_NOTE_BLOCKS,
  FETCH_DOCUMENT,
  CREATE_DOCUMENT,
  // UPDATE_DOCUMENT,
  // DELETE_DOCUMENT,
  // MOVE_DOCUMENT,
  // CREATE_FOLDER,
  // REGISTER_VIDEO,
  // UPDATE_VIDEO,
  FETCH_DOCUMENTS,
  FETCH_DELETED_DOCUMENTS,
  SET_VIDEO_INDEX,
  SET_CAPTURE_INSERT_POSITION,
  SET_IS_EDITOR_SAVED,
  // SET_EDITOR_MODE,
  TOGGLE_EDITOR,
  SET_VIDEO_PLACEHOLDER_RECT,
  SET_CAPTURE_AREA_RECT,
  SET_IS_CAPTURE_AREA_ACTIVE,
  SET_CURRENT_VIDEO,
  UPDATE_CURRENT_VIDEO,
  FETCH_VIDEOS,
  SET_SHOW_SEARCH_POPUP,
  SET_SHOW_SHARE_POPUP,
  SET_SHOW_IMAGE_MARKUP_POPUP,
  SET_SHOW_IMAGE_FULL_SCREEN,
  SET_NEED_TO_SIGN_UP,
  SET_SHOW_DELETED_DOCUMENTS_POPUP,
  SET_IS_LIST_OPEN,
  SET_IS_READ_ONLY,
  SET_IS_EDITOR_OPTION_OPEN,
  SET_IS_PDF_UPLOADER_CLICKED,
  SET_SHOW_DISABLE_MEDIA_RECORDING_MODAL,
  SET_MEDIA_RECORDING_CHANGE_DIRECTION,
  SET_IS_WEB_CAPTURE_AVAILABLE,
  SET_IS_SET_CAPTURE_AREA_BUTTON_CLICKED,

  // for history
  GET_DOCUMENT_HISTORY_LIST,
  // CREATE_DOCUMENT_HISTORY,

  // for slid desktop
  SET_SELECTED_SOURCE_DATA,
  SET_IFRAME_PORT,
  SET_DESKTOP_DEFAULT_CAPTURE_RECT,
  SET_DESKTOP_CAPTURE_RECT,
  SET_DESKTOP_CAPTURE_RECT_2,
  SET_IS_GUEST_MODE_ALERT_SHOWN,
  SET_IS_DESKTOP_CAPTURE_AREA_SETTING,
  SET_IS_DESKTOP_CAPTURE_AREA_SET,

  // editor instance
  SET_EDITOR_INSTANCE,
  SET_UNDO_INSTANCE,

  // video player
  SET_VIDEO_PLAYER_REF,
  SET_IS_VIDEO_PLAYING,

  // editor
  SET_EDITOR_WRAPPER_CLASS_NAME,
  SET_SHOW_PLACEHOLDER,
  SET_EDITOR_LAST_ACTIVE_BLOCK_POSITION,
  SET_IS_RECORD_ACTIVE,
  SET_LOADER_BLOCK_KEYS,
  SET_IS_CAPTURE_AREA_SET_FOR_SNIP_CAPTURE,
  SET_CAPTURE_RECT,
  SET_IS_CAPTURE_AREA_SET_FOR_ONE_CLICK_CAPTURE,
  SET_IS_CAPTURE_AREA_RESET,
  SET_IS_HELP_MENU_OPEN,
  SET_IS_SAVE_DOCUMENT_CALLED,
  SET_CURRENT_FOLDER,
  SET_SELECTABLE_PROCESSES,

  // app loading state
  SET_IS_EDITOR_LOADING,
  SET_IS_EDITOR_NOTE_LOADING,

  // for extension media capture permission
  SET_IS_EXTENSION_MEDIA_PERMITTED,
  SET_IS_STT_SUPPORTED,
  SET_IS_GETTING_EXTENSION_MEDIA_PERMISSION,
  SET_SHOW_EXTENSION_MEDIA_PERMISSION_MODAL,
  SET_IS_GETTING_CLIP_RECORDING_MEDIA_PERMISSION,

  // For Tablet & Image cropping
  SET_IS_IMAGE_CROPPING_MODAL_OPEN,
  SET_IMAGE_CROP_AREA,
  SET_IMAGE_CROPPING_MODAL_IMAGE_SOURCE,
  SET_EDIT_IMAGE_CROP_AREA,
  SET_SHOULD_UPDATE_IMAGE_BY_CROP,
  SET_CROPPING_IMAGE_BLOCK_DATA,
  SET_TABLET_DEFAULT_CROP_AREA,
} from "./actionTypes";

import {
  ServerInterface_getDocuments,
  ServerInterface_getDocument,
  ServerInterface_deleteDocument,
  ServerInterface_restoreDocument,
  ServerInterface_deleteDocumentPermanently,
  ServerInterface_createDocument,
  ServerInterface_updateDocument,
  ServerInterface_patchDocument,
  ServerInterface_moveToFolder,
} from "api/documentsApi";
import { ServerInterface_getDeletedDocuments } from "api/deletedDocumentsApi";
import { ServerInterface_getDocumentHistoryList, ServerInterface_createDocumentHistory } from "api/historyApi";
import { ServerInterface_searchDocument } from "api/searchApi";
import { ServerInterface_registerVideoToDocument } from "api/videosApi";
import { ServerInterface_registerClip, ServerInterface_updateClip, ServerInterface_deleteClip } from "api/clipsApi";
import { ServerInterface_registerClipOCR, ServerInterface_getClipOCRResults } from "api/ocrApi";
import { ServerInterface_fetchVideos } from "api/videosApi";
import { ServerInterface_getLinkPreview } from "api/linkPreviewApi";

import { sendAmplitudeData } from "utils/amplitude";
import { trackEvent } from "utils/eventTracking";
import * as Sentry from "@sentry/browser";
import store from "../store";
import { setDefaultSettingCookie, getDefaultSettingCookie } from "utils/cookies/cookies";
import { MediaRecordingChangeDirection } from "components/popups/DisableMediaRecordingModal"; //NOTE: don't delete this as its used for adding a type to setMediaRecordingChangeDirection

export const setIsSetCaptureAreaButtonClicked = (isSetCaptureAreaButtonClicked) => (dispatch) => {
  dispatch({
    type: SET_IS_SET_CAPTURE_AREA_BUTTON_CLICKED,
    payload: isSetCaptureAreaButtonClicked,
  });
};

export const setIsWebCaptureAvailable = (isWebCaptureAvailable) => (dispatch) => {
  dispatch({
    type: SET_IS_WEB_CAPTURE_AVAILABLE,
    payload: isWebCaptureAvailable,
  });
};

export const setDocument = (currentDocument) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENT,
    payload: currentDocument,
  });
};

export const setNoteBlocks = (noteBlocks) => (dispatch) => {
  dispatch({
    type: SET_NOTE_BLOCKS,
    payload: noteBlocks,
  });
};

export const fetchDocument =
  ({ documentKey, showError = true }) =>
  async (dispatch) => {
    const documentData = await ServerInterface_getDocument({
      documentKey: documentKey,
    });
    if (documentData["error_message"]) {
      if (showError) {
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: documentData["error_message"],
        });
      }
      return documentData;
    }
    dispatch({
      type: FETCH_DOCUMENT,
      payload: documentData,
    });
    return documentData;
  };

export const createDocument =
  ({ parentKey = "root", title = "", content = {}, origin }) =>
  async (dispatch) => {
    if (content === "") return;
    dispatch(setIsEditorSaved(false));
    const documentData = await ServerInterface_createDocument({
      data: {
        title: title,
        content: JSON.stringify(content),
        parent_key: parentKey,
      },
    });
    if (documentData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: documentData["error_message"],
      });
      dispatch(setIsEditorSaved(true));
      return documentData;
    }
    dispatch({
      type: CREATE_DOCUMENT,
      payload: documentData,
    });
    dispatch(setIsEditorSaved(true));
    sendAmplitudeData(`SLID_1_CREATE_DOCUMENT`);
    trackEvent({
      eventType: "Create new note",
      eventProperties: {
        origin: origin ? origin : null,
      },
    });
    return documentData;
  };

export const updateDocument =
  ({ documentKey, title = "", content, more_info = null, fetchCurrentDocument = true, showError = true, origin }) =>
  async (dispatch) => {
    if (content === "") return;
    dispatch(setIsEditorSaved(false));
    const data = {
      title: title,
      content: content ? JSON.stringify(content) : undefined,
    };
    if (more_info) {
      data.more_info = JSON.stringify(more_info);
    }
    const updatedDocumentData = await ServerInterface_updateDocument({
      documentKey: documentKey,
      data: data,
    });
    if (updatedDocumentData["error_message"]) {
      if (updatedDocumentData["error_message"] === "INSUFFICIENT_PRIVILEGES") {
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: updatedDocumentData["error_message"],
        });
      } else if (showError) {
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: updatedDocumentData["error_message"],
        });
      }
      dispatch(setIsEditorSaved(true));
      return updatedDocumentData;
    }
    if (fetchCurrentDocument) {
      await dispatch(
        fetchDocument({
          documentKey: documentKey,
        })
      );
    }
    dispatch(setIsEditorSaved(true));
    return updatedDocumentData;
  };

export const patchDocument =
  ({ documentKey, options }) =>
  async (dispatch) => {
    const updatedDocumentData = await ServerInterface_patchDocument({
      documentKey: documentKey,
      data: options,
    });
    if (updatedDocumentData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: updatedDocumentData["error_message"],
      });
      return updatedDocumentData;
    }
    await dispatch(
      fetchDocument({
        documentKey: documentKey,
      })
    );
    return updatedDocumentData;
  };

export const removeDocument =
  ({ documentKey, location, from, isFolder }) =>
  async (dispatch) => {
    const deletedDocumentData = await ServerInterface_deleteDocument({
      documentKey: documentKey,
    });
    if (deletedDocumentData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: deletedDocumentData["error_message"],
      });
      return deletedDocumentData;
    }
    trackEvent({
      eventType: "Delete file",
      eventProperties: {
        location: location || null,
        from: from || null,
        isFolder: isFolder,
      },
    });
    // if the deleted document was the recent document in cookie, reset recent document
    if (getDefaultSettingCookie({ property: `recentNoteDocumentKey` }) === documentKey) {
      setDefaultSettingCookie({
        property: `recentNoteDocumentKey`,
        value: "new",
      });
    }

    return deletedDocumentData;
  };

export const restoreDocument =
  ({ documentKey }) =>
  async (dispatch) => {
    const restoredDocumentData = await ServerInterface_restoreDocument({
      documentKey: documentKey,
    });
    if (restoredDocumentData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: restoredDocumentData["error_message"],
      });
      return restoredDocumentData;
    }
    trackEvent({
      eventType: "Restore note",
    });
    return restoredDocumentData;
  };

export const removeDocumentPermanently =
  ({ documentKey }) =>
  async (dispatch) => {
    const documentData = await ServerInterface_deleteDocumentPermanently({
      documentKey: documentKey,
    });
    if (documentData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: documentData["error_message"],
      });
      return documentData;
    }
    trackEvent({
      eventType: "Delete note permanently",
    });
    return documentData;
  };

export const getDocumentHistoryList =
  ({ documentKey }) =>
  async (dispatch) => {
    const documentHistoryList = await ServerInterface_getDocumentHistoryList({ documentKey: documentKey });
    if (documentHistoryList["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: documentHistoryList["error_message"],
      });
      return documentHistoryList;
    }
    dispatch({
      type: GET_DOCUMENT_HISTORY_LIST,
      payload: documentHistoryList,
    });
    return documentHistoryList;
  };

export const createDocumentHistory =
  ({ documentKey, showError = true, origin }) =>
  async (dispatch) => {
    const documentHistory = await ServerInterface_createDocumentHistory({ documentKey: documentKey });
    if (documentHistory["error_message"]) {
      if (showError) {
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: documentHistory["error_message"],
        });
      }
      return;
    }
    await dispatch(getDocumentHistoryList({ documentKey: documentKey }));
    return documentHistory;
  };

export const searchDocuments = (searchKeyword) => async (dispatch) => {
  const searchDocumentResult = await ServerInterface_searchDocument({
    data: {
      keyword: searchKeyword,
    },
  });
  if (searchDocumentResult["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: searchDocumentResult["error_message"],
    });
    return searchDocumentResult;
  }
  return searchDocumentResult;
};

export const createFolder =
  ({ title, parentKey, origin }) =>
  async (dispatch) => {
    const { lang } = store.getState().slidGlobal;

    const createdFolder = await ServerInterface_createDocument({
      data: {
        title: title === "" ? (lang === "ko" ? "제목없는 폴더" : "Untitled folder") : title,
        is_folder: true,
        parent_key: parentKey,
      },
    });
    if (createdFolder["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: createdFolder["error_message"],
      });
      return createdFolder;
    }
    sendAmplitudeData(`SLID_1_CREATE_FOLDER`);
    trackEvent({
      eventType: "Create new folder",
      eventProperties: {
        origin: origin ? origin : null,
      },
    });
    return createdFolder;
  };

export const updateFolder =
  ({ documentKey, content = null, currentDocumentKey, title, origin }) =>
  async (dispatch) => {
    dispatch(setIsEditorSaved(false));
    const updatedFolderData = await ServerInterface_updateDocument({
      documentKey: documentKey,
      data: {
        content: content ? JSON.stringify(content) : null,
        title: title,
      },
    });
    if (updatedFolderData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: updatedFolderData["error_message"],
      });
      return updatedFolderData;
    }
    if (currentDocumentKey) {
      await dispatch(
        fetchDocument({
          documentKey: currentDocumentKey,
        })
      );
    }
    trackEvent({
      eventType: "Update folder",
      eventProperties: {
        origin: origin ? origin : null,
      },
    });
    return updatedFolderData;
  };

export const moveDocument =
  ({ parentKey, documentKeys, location, from, type }) =>
  async (dispatch) => {
    let moveToFolderResponse = await ServerInterface_moveToFolder({
      parentKey: parentKey,
      documentKeys: documentKeys,
    });
    if (moveToFolderResponse["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: moveToFolderResponse["error_message"],
      });
      return moveToFolderResponse;
    }
    sendAmplitudeData(`SLID_1_MOVE_DOCUMENT_TO_FOLDER`);
    trackEvent({
      eventType: "Move file to folder",
      eventProperties: {
        location: location || null,
        from: from || null,
        type: type || null,
      },
    });
    return moveToFolderResponse;
  };

export const setIsEditorSaved = (isEditorSaved) => (dispatch) => {
  dispatch({
    type: SET_IS_EDITOR_SAVED,
    payload: isEditorSaved,
  });
};

export const toggleEditor = () => (dispatch) => {
  dispatch({
    type: TOGGLE_EDITOR,
    payload: null,
  });
};

export const setVideoPlaceholderRect = (videoPlaceholderRect) => (dispatch) => {
  dispatch({
    type: SET_VIDEO_PLACEHOLDER_RECT,
    payload: videoPlaceholderRect,
  });
};

export const setCaptureAreaRect = (captureAreaRect) => (dispatch) => {
  dispatch({
    type: SET_CAPTURE_AREA_RECT,
    payload: captureAreaRect,
  });
};

export const setIsCaptureAreaActive = (isCaptureAreaActive) => (dispatch) => {
  dispatch({
    type: SET_IS_CAPTURE_AREA_ACTIVE,
    payload: isCaptureAreaActive,
  });
};

export const setCurrentVideo = (videoInfo) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_VIDEO,
    payload: videoInfo,
  });
};

export const updateCurrentVideo = (updatedVideoInfo) => (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_VIDEO,
    payload: updatedVideoInfo,
  });
};

export const fetchVideos = () => async (dispatch) => {
  const videosData = await ServerInterface_fetchVideos();
  if (!videosData) return;
  if (videosData["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: videosData["error_message"],
    });
    return videosData;
  }

  if (videosData) {
    dispatch({
      type: FETCH_VIDEOS,
      payload: videosData.videos,
    });
  }
};

// register video to document
export const registerVideo =
  ({ videoInfo, documentKey }) =>
  async (dispatch) => {
    if (!documentKey) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("Tried to register video to document without document key");
      });
      return;
    }
    if (!videoInfo) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("Tried to register video to document without video info");
      });
      return;
    }
    if (!videoInfo.videoUniqueKey || !videoInfo.videoUrl || !videoInfo.originUrl || !videoInfo.videoType) {
      return;
    }

    const videoRegisterInfo = await ServerInterface_registerVideoToDocument({
      data: {
        video_unique: videoInfo.videoUniqueKey,
        video_origin_url: videoInfo.videoUrl,
        website_origin_url: videoInfo.originUrl,
        video_type: videoInfo.videoType,
        video_download_url: videoInfo.videoDownloadUrl,
        document_key: documentKey,
      },
    });

    if (videoRegisterInfo["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: videoRegisterInfo["error_message"],
      });
      return videoRegisterInfo;
    }

    await dispatch(
      fetchDocument({
        documentKey: documentKey,
      })
    );

    return videoRegisterInfo;
  };

export const registerClip =
  ({ imgSrc, clipTimestamp, documentKey, videoKey, clipEndPoint }) =>
  async (dispatch) => {
    const clipRegisterResponse = await ServerInterface_registerClip({
      data: {
        img_src: imgSrc,
        clip_ts: clipTimestamp,
        document_key: documentKey,
        video_key: videoKey,
      },
      clipEndPoint,
    });

    if (clipRegisterResponse["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: clipRegisterResponse["error_message"],
      });
      return clipRegisterResponse;
    }
    return clipRegisterResponse;
  };

export const registerClipOCR =
  ({ clipKey, documentKey, videoKey, imgSrc }) =>
  async (dispatch) => {
    const registerClipOCRResponse = await ServerInterface_registerClipOCR({
      data: {
        clip_key: clipKey,
        document_key: documentKey,
        video_key: videoKey,
        img_src: imgSrc,
      },
    });
    if (registerClipOCRResponse["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: registerClipOCRResponse["error_message"],
      });

      return registerClipOCRResponse;
    }
    return registerClipOCRResponse;
  };

export const getClipOCRResults =
  ({ clipKey }) =>
  async (dispatch) => {
    const clipOcrGetResult = await ServerInterface_getClipOCRResults({ clip_key: clipKey });
    if (clipOcrGetResult["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: clipOcrGetResult["error_message"],
      });
      return clipOcrGetResult;
    }
    return clipOcrGetResult;
  };

export const updateClip =
  ({ data }) =>
  async (dispatch) => {
    const clipUpdateResponse = await ServerInterface_updateClip({ data: data });
    if (clipUpdateResponse["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: clipUpdateResponse["error_message"],
      });
      return clipUpdateResponse;
    }
    return clipUpdateResponse;
  };

export const deleteClip =
  ({ clipKey, serverErrorHandler = () => {} }) =>
  async (dispatch) => {
    const data = {
      clipKey,
    };
    const clipDeleteResponse = await ServerInterface_deleteClip({ data });
    if (clipDeleteResponse["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: clipDeleteResponse["error_message"],
      });
      return clipDeleteResponse;
    }
  };

export const fetchDocuments =
  (documentKey, shouldDispatch = true) =>
  async (dispatch) => {
    const documentData = await ServerInterface_getDocuments({ documentKey: documentKey });
    if (documentData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: documentData["error_message"],
      });
      return documentData;
    }
    if (shouldDispatch) {
      dispatch({
        type: FETCH_DOCUMENTS,
        payload: documentData.documents,
      });
    }
    return documentData;
  };

export const fetchDeletedDocuments = () => async (dispatch) => {
  const deletedDocumentsData = await ServerInterface_getDeletedDocuments();
  if (deletedDocumentsData["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: deletedDocumentsData["error_message"],
    });
    return deletedDocumentsData;
  }
  dispatch({
    type: FETCH_DELETED_DOCUMENTS,
    payload: deletedDocumentsData.documents,
  });
  return deletedDocumentsData;
};

export const getLinkPreviewData = (url) => async (dispatch) => {
  const result = await ServerInterface_getLinkPreview({ url });
  if (result["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: result["error_message"],
    });
    return result;
  }
  return result;
};

export const setVideoIndex = (videoIndex) => (dispatch) => {
  dispatch({
    type: SET_VIDEO_INDEX,
    payload: videoIndex,
  });
};

export const setCaptureInsertPosition = (captureInsertPosition) => (dispatch) => {
  dispatch({
    type: SET_CAPTURE_INSERT_POSITION,
    payload: captureInsertPosition,
  });
};

export const setShowSearchPopup = (showSearchPopup) => (dispatch) => {
  dispatch({
    type: SET_SHOW_SEARCH_POPUP,
    payload: showSearchPopup,
  });
};

export const setShowSharePopup = (showSharePopup) => (dispatch) => {
  dispatch({
    type: SET_SHOW_SHARE_POPUP,
    payload: showSharePopup,
  });
};

export const setShowImageMarkupPopup = (showImageMarkupPopup) => (dispatch) => {
  dispatch({
    type: SET_SHOW_IMAGE_MARKUP_POPUP,
    payload: showImageMarkupPopup,
  });
};

export const setShowImageFullScreen = (showImageFullScreen) => (dispatch) => {
  dispatch({
    type: SET_SHOW_IMAGE_FULL_SCREEN,
    payload: showImageFullScreen,
  });
};

export const setNeedToSignUp = (needToSignUp) => (dispatch) => {
  dispatch({
    type: SET_NEED_TO_SIGN_UP,
    payload: needToSignUp,
  });
};

export const setShowDeletedDocumentsPopup = (showDeletedDocumentsPopup) => (dispatch) => {
  dispatch({
    type: SET_SHOW_DELETED_DOCUMENTS_POPUP,
    payload: showDeletedDocumentsPopup,
  });
};

export const setIsListOpen = (isListOpen) => (dispatch) => {
  dispatch({
    type: SET_IS_LIST_OPEN,
    payload: isListOpen,
  });
};

export const setIsEditorOptionOpen = (isEditorOptionOpen) => (dispatch) => {
  dispatch({
    type: SET_IS_EDITOR_OPTION_OPEN,
    payload: isEditorOptionOpen,
  });
};

export const setIsPDFUploaderClicked = (isPDFUploaderClicked) => (dispatch) => {
  dispatch({
    type: SET_IS_PDF_UPLOADER_CLICKED,
    payload: isPDFUploaderClicked,
  });
};

// do not recreate new instance unless necessary
// recreating editor instance will mess up undo instance.
export const setEditorInstance = (editorInstance) => (dispatch) => {
  dispatch({
    type: SET_EDITOR_INSTANCE,
    payload: editorInstance,
  });
};

// do not recreate new instance unless necessary
// recreating undo instance will loose all the histories.
export const setUndoInstance = (undoInstance) => (dispatch) => {
  dispatch({
    type: SET_UNDO_INSTANCE,
    payload: undoInstance,
  });
};

export const setIsReadOnly = (isReadOnly) => (dispatch) => {
  dispatch({
    type: SET_IS_READ_ONLY,
    payload: isReadOnly,
  });
};

export const setVideoPlayerRef = (videoPlayerRef) => (dispatch) => {
  dispatch({
    type: SET_VIDEO_PLAYER_REF,
    payload: videoPlayerRef,
  });
};

export const setEditorWrapperClassName = (editorWrapperClassName) => (dispatch) => {
  dispatch({
    type: SET_EDITOR_WRAPPER_CLASS_NAME,
    payload: editorWrapperClassName,
  });
};

export const setEditorLastActiveBlockPosition = (editorLastActiveBlockPosition) => (dispatch) => {
  dispatch({
    type: SET_EDITOR_LAST_ACTIVE_BLOCK_POSITION,
    payload: editorLastActiveBlockPosition,
  });
};

export const setIsRecordActive = (isRecordActive) => (dispatch) => {
  dispatch({
    type: SET_IS_RECORD_ACTIVE,
    payload: isRecordActive,
  });
};

export const setLoaderBlockKeys = (loaderBlockKeys) => (dispatch) => {
  dispatch({
    type: SET_LOADER_BLOCK_KEYS,
    payload: loaderBlockKeys,
  });
};

export const setIsHelpMenuOpen = (isHelpMenuOpen) => (dispatch) => {
  dispatch({
    type: SET_IS_HELP_MENU_OPEN,
    payload: isHelpMenuOpen,
  });
};

export const setIsSaveDocumentCalled = (isSaveDocumentCalled) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVE_DOCUMENT_CALLED,
    payload: isSaveDocumentCalled,
  });
};

// Action creators for Slid Desktop
export const setIframePort = (port) => (dispatch) => {
  dispatch({
    type: SET_IFRAME_PORT,
    payload: port,
  });
};

export const setIsGuestModeAlertShown = (showGuestModeAlert) => (dispatch) => {
  dispatch({
    type: SET_IS_GUEST_MODE_ALERT_SHOWN,
    payload: showGuestModeAlert,
  });
};

export const setSelectedSourceData = (selectedSourceData) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_SOURCE_DATA,
    payload: selectedSourceData,
  });
};

export const setDesktopDefaultCaptureRect = (defaultCaptureRect) => (dispatch) => {
  dispatch({
    type: SET_DESKTOP_DEFAULT_CAPTURE_RECT,
    payload: defaultCaptureRect,
  });
};

export const setDesktopCaptureRect = (newBounds) => (dispatch) => {
  dispatch({
    type: SET_DESKTOP_CAPTURE_RECT,
    payload: newBounds,
  });
};

export const setDesktopCaptureRect2 = (newBounds) => (dispatch) => {
  dispatch({
    type: SET_DESKTOP_CAPTURE_RECT_2,
    payload: newBounds,
  });
};

export const setIsDesktopCaptureAreaSetting = (isDesktopCaptureAreaSetting) => (dispatch) => {
  dispatch({
    type: SET_IS_DESKTOP_CAPTURE_AREA_SETTING,
    payload: isDesktopCaptureAreaSetting,
  });
};

export const setIsDesktopCaptureAreaSet = (isDesktopCaptureAreaSet) => (dispatch) => {
  dispatch({
    type: SET_IS_DESKTOP_CAPTURE_AREA_SET,
    payload: isDesktopCaptureAreaSet,
  });
};

export const setIsCaptureAreaSetForSnipCapture = (isCaptureAreaSetForSnipCapture) => (dispatch) => {
  dispatch({
    type: SET_IS_CAPTURE_AREA_SET_FOR_SNIP_CAPTURE,
    payload: isCaptureAreaSetForSnipCapture,
  });
};

export const setCaptureRect = (captureRect) => (dispatch) => {
  dispatch({
    type: SET_CAPTURE_RECT,
    payload: captureRect,
  });
};

export const setIsCaptureAreaSetForOneClickCapture = (isCaptureAreaSetForOneClickCapture) => (dispatch) => {
  dispatch({
    type: SET_IS_CAPTURE_AREA_SET_FOR_ONE_CLICK_CAPTURE,
    payload: isCaptureAreaSetForOneClickCapture,
  });
};

export const setIsCaptureAreaReset = (isCaptureAreaReset) => (dispatch) => {
  dispatch({
    type: SET_IS_CAPTURE_AREA_RESET,
    payload: isCaptureAreaReset,
  });
};

export const setCurrentFolder = (currentFolder) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_FOLDER,
    payload: currentFolder,
  });
};

export const setSelectableProcesses = (selectableProcesses) => (dispatch) => {
  dispatch({
    type: SET_SELECTABLE_PROCESSES,
    payload: selectableProcesses,
  });
};

export const setIsEditorLoading = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_EDITOR_LOADING,
    payload: status,
  });
};

export const setIsEditorNoteLoading = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_EDITOR_NOTE_LOADING,
    payload: status,
  });
};

export const setIsVideoPlaying = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_VIDEO_PLAYING,
    payload: status,
  });
};

export const setIsExtensionMediaPermitted = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_EXTENSION_MEDIA_PERMITTED,
    payload: status,
  });
};

export const setShowDisableMediaRecordingModal = (status) => (dispatch) => {
  dispatch({
    type: SET_SHOW_DISABLE_MEDIA_RECORDING_MODAL,
    payload: status,
  });
};

/**
 * Sets the direction of media recording change.
 * @param {MediaRecordingChangeDirection} direction - The direction of the media recording change.
 */
export const setMediaRecordingChangeDirection = (direction) => (dispatch) => {
  dispatch({
    type: SET_MEDIA_RECORDING_CHANGE_DIRECTION,
    payload: direction,
  });
};

export const setIsSTTSupported = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_STT_SUPPORTED,
    payload: status,
  });
};

export const setIsGettingExtensionMediaPermission = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_GETTING_EXTENSION_MEDIA_PERMISSION,
    payload: status,
  });
};

export const setShowExtensionMediaPermissionModal = (showModal) => (dispatch) => {
  dispatch({
    type: SET_SHOW_EXTENSION_MEDIA_PERMISSION_MODAL,
    payload: showModal,
  });
};

// Tablet & Image cropper
export const setIsImageCroppingModalOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: SET_IS_IMAGE_CROPPING_MODAL_OPEN,
    payload: isOpen,
  });
};

export const setImageCropArea = (data) => (dispatch) => {
  dispatch({
    type: SET_IMAGE_CROP_AREA,
    payload: data,
  });
};

export const setImageCroppingModalImageSource = (imageSource) => (dispatch) => {
  dispatch({
    type: SET_IMAGE_CROPPING_MODAL_IMAGE_SOURCE,
    payload: imageSource,
  });
};

export const setEditImageCropArea = (editImageCropArea) => (dispatch) => {
  dispatch({
    type: SET_EDIT_IMAGE_CROP_AREA,
    payload: editImageCropArea,
  });
};
export const setShouldUpdateImageByCrop = (shouldUpdateImageByCrop) => (dispatch) => {
  dispatch({
    type: SET_SHOULD_UPDATE_IMAGE_BY_CROP,
    payload: shouldUpdateImageByCrop,
  });
};
export const setCroppingImageBlockData = (croppingImageBlockData) => (dispatch) => {
  dispatch({
    type: SET_CROPPING_IMAGE_BLOCK_DATA,
    payload: croppingImageBlockData,
  });
};

export const setIsGettingClipRecordingMediaPermission = (isGettingClipRecordingMediaPermission) => (dispatch) => {
  dispatch({
    type: SET_IS_GETTING_CLIP_RECORDING_MEDIA_PERMISSION,
    payload: isGettingClipRecordingMediaPermission,
  });
};
